import React from "react";
import styled from "styled-components";

// Define the styles for the container and elements
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #282c34;
`;

const ErrorText = styled.p`
  color: red;
  font-size: 20px;
  margin-top: 20px;
`;

// The Loading component
const Loading = ({ error }) => {
  if (error) {
    return (
      <LoadingContainer>
        <ErrorText> Camera not found</ErrorText>
      </LoadingContainer>
    );
  } /*else if (isEmpty) {
    <LoadingContainer>
      <ErrorText>No Images to load</ErrorText>
    </LoadingContainer>;
  } */
};

export default Loading;
