import React, { useState, useEffect } from "react";
import "../App.css";
import "../css/bootstrap.min.css";
import useFetchData from "../hooks/useFetchData";
import LogoCard from "./LogoCard";
import ProjectAndCamerasCard from "./ProjectAndCameras";
import Loading from "./LoadingAndError";
import ReportAndDownloadButton from "./ReportAndDownloadButton";
import Images from "./ImageComponents/Images";
import FilterDate from "./ImageComponents/FilterDate";
import PlayButtonWithSlider from "./ImageComponents/PlayButtonWithSlider";
import { useParams } from "react-router-dom";
import DropdownFilteringMenu from "./DropdownFilteringMenu";
import PrevAndNextButtons from "./ImageComponents/PrevAndNextButtons";
import Modal from "react-modal";
import Footer from "./Footer";

function MainComponent() {
  const [selected, setSelected] = useState("All Images");
  const { id } = useParams();
  const {
    loading,
    data,
    images,
    error,
    isModalOpen,
    setIsModalOpen,
    sendPassword,
    falsePassword,
    percentage,
  } = useFetchData(id);
  const [index, setIndex] = useState(0);
  const [filteredIndexNumbers, setFilteredIndexNumbers] = useState([]);
  const [prevAndNextIndex, setPrevAndNextIndex] = useState(0);
  const [filterDate, setFilterDate] = useState("");
  const [intervalId, setIntervalId] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [password, setPassword] = useState("");

  const findClosestInteger = (arr, target) => {
    return arr.reduce((prev, curr) =>
      Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev
    );
  };

  const handleSliderChange = (event) => {
    if (selected === "Day" || selected === "Week" || selected === "Month") {
      const result = findClosestInteger(
        filteredIndexNumbers,
        event.target.value
      );
      setIndex(result);
      if (typeof images[event.target.value] === "undefined") {
        setIndex(images.length - 1);
      }
    } else {
      setIndex(event.target.value);
    }
  };

  useEffect(() => {
    if (images.length > 0 && index >= 0) {
      let date = new Date(images[index].timestamp);
      setFilterDate((prevDate) => {
        if (prevDate !== date) {
          return date;
        }
        return prevDate;
      });
    }
  }, [index, images]);

  useEffect(() => {
    if (!loading && images) {
      setIndex(0);
      /*setIndex((prevIndex) => {
        if (prevIndex !== images.length - 1) {
          return images.length - 1;
        }
        return prevIndex;
      });*/
    }
  }, [loading]);

  const goToNextImage = () => {
    if (selected === "Day" || selected === "Week" || selected === "Month") {
      setIndex((prevIndex) => {
        if (prevIndex === filteredIndexNumbers[prevAndNextIndex]) {
          let newIndexNumber = Number(prevAndNextIndex - 1);
          if (newIndexNumber <= 0) {
            return filteredIndexNumbers[0];
          }
          setPrevAndNextIndex(Number(prevAndNextIndex) - 1);
          return filteredIndexNumbers[newIndexNumber];
        } else {
          const result = findClosestInteger(filteredIndexNumbers, prevIndex);
          let indexNumber = filteredIndexNumbers.indexOf(result);
          if (result > prevIndex) {
            indexNumber = indexNumber - 1;
          }
          setPrevAndNextIndex(indexNumber);
          return filteredIndexNumbers[indexNumber];
        }
      });
    } else {
      setIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));
    }
  };

  const goToPreviousImage = () => {
    if (selected === "Day" || selected === "Week" || selected === "Month") {
      setIndex((prevIndex) => {
        if (prevIndex === filteredIndexNumbers[prevAndNextIndex]) {
          let newIndexNumber = Number(prevAndNextIndex + 1);
          if (newIndexNumber >= filteredIndexNumbers.length) {
            return filteredIndexNumbers[filteredIndexNumbers.length - 1];
          }
          setPrevAndNextIndex(Number(prevAndNextIndex) + 1);
          return filteredIndexNumbers[newIndexNumber];
        } else {
          const result = findClosestInteger(filteredIndexNumbers, prevIndex);
          let indexNumber = filteredIndexNumbers.indexOf(result);
          if (result > prevIndex) {
            indexNumber = indexNumber + 1;
          }
          setPrevAndNextIndex(indexNumber);
          return filteredIndexNumbers[indexNumber];
        }
      });
    } else {
      let prevIndex = index;
      if (Number(prevIndex) + 1 > images.length - 1) {
        setIndex(images.length - 1);
        return;
      }
      setIndex(Number(prevIndex) + 1);
    }
  };

  useEffect(() => {
    if (percentage === 100) {
      setFilteredIndexNumbers([...Array(images.length).keys()]);
    }
  }, [images]);

  useEffect(() => {
    if (!isPlaying || filteredIndexNumbers.length === 0) {
      return;
    }

    if (intervalId) {
      clearInterval(intervalId);
    }

    const id = setInterval(() => {
      setIndex((prevIndex) => {
        const closestInteger = findClosestInteger(
          filteredIndexNumbers,
          prevIndex
        );
        let closestIntegerIndex = filteredIndexNumbers.indexOf(closestInteger);
        let currentIdx = closestIntegerIndex;
        const nextIdx = currentIdx - 1;
        if (currentIdx === 0) {
          return filteredIndexNumbers[filteredIndexNumbers.length - 1];
        }
        return filteredIndexNumbers[nextIdx];
      });
    }, 500);

    setIntervalId(id);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredIndexNumbers, isPlaying]);

  const playImages = () => {
    if (isPlaying) {
      clearInterval(intervalId);
      setIntervalId(null);
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    sendPassword(password); // Submit the password from modal
  };

  if (isModalOpen) {
    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        shouldCloseOnOverlayClick={false}
        overlayClassName="modal-overlay"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
          },
        }}
      >
        <form onSubmit={handlePasswordSubmit}>
          {falsePassword && (
            <span
              id="falsePassword"
              style={{ color: "red", marginBottom: "10px", display: "block" }}
            >
              Password incorrect
            </span>
          )}
          <input
            type="password"
            autoComplete="on"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
          />
          <button type="submit" id="submitModalButton" className="modal-button">
            Submit
          </button>
        </form>
      </Modal>
    );
  }

  if (!data || !data.images || data.images.length === 0)
    return <Loading error={error}></Loading>;
  if (!data.images[0].accessUrl) return <div>No Images to load</div>;

  return (
    <div className="App" style={{ backgroundColor: "#f8f8fd" }}>
      <div className="slider-container">
        <LogoCard headerLogo={data.headerLogo} />
        <ProjectAndCamerasCard
          projectName={data.projectName}
          projectCameras={data.projectCameras}
        />
        <ReportAndDownloadButton
          data={data}
          index={index}
          images={images}
        ></ReportAndDownloadButton>
        <Images baseUrl={data.baseUrl} images={images} index={index}></Images>
        <div className="row justify-content-center">
          <FilterDate
            filterDate={filterDate}
            setFilterDate={setFilterDate}
            images={images}
            setIndex={setIndex}
          ></FilterDate>
          <DropdownFilteringMenu
            selected={selected}
            setSelected={setSelected}
            images={images}
            index={index}
            setFilteredIndexNumbers={setFilteredIndexNumbers}
          ></DropdownFilteringMenu>
          <PrevAndNextButtons
            goToPreviousImage={goToPreviousImage}
            goToNextImage={goToNextImage}
          ></PrevAndNextButtons>
        </div>
        <PlayButtonWithSlider
          playImages={playImages}
          intervalId={intervalId}
          images={images}
          index={index}
          handleSliderChange={handleSliderChange}
          data={data}
          percentage={percentage}
        ></PlayButtonWithSlider>
        <Footer data={data}></Footer>
      </div>
    </div>
  );
}
export default MainComponent;
