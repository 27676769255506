import React from "react";

const Footer = ({ data }) => {
  const customFooterHtml = data?.customFooterText?.trim() || "";
  console.log(data);
  if (!customFooterHtml) {
    return null; // Do not render if no content is provided
  }

  return (
    <div className="row justify-content-center">
      <div
        className="col-lg-6 text-lg-end my-2 mx-lg-2 text-center"
        dangerouslySetInnerHTML={{ __html: customFooterHtml }}
      />
    </div>
  );
};

export default Footer;
